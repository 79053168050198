import { s3 } from "fine-uploader/lib/s3";

import config from "../../configuration";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  isFetchBaseQueryError,
  useGetCollectionQuery,
  useGetCollectionsMetaQuery,
  useGetCollectionsQuery,
  useSaveCollectionMutation,
} from "../../slices/apiSlice";
import { classNameMapper } from "../../utils/classNameMapper";
import { CollectionType, CollectionTypes } from "../../types/CollectionTypes";
import { ICollection, Property, Type } from "../../types/ICollection";
import { Checkbox } from "../../design/Checkbox";
import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import { FoundUploadItems } from "fine-uploader/lib/core";
import { Button } from "../../design/Button";
import { SqlEditor } from "./SqlEditor";
import { IndexesCreator } from "./IndexesCreator";
import { SmallLoadingIndicator } from "../../design/SmallLoadingIndicator";
import { SaveAsMasterModal } from "./SaveAsMasterModal";
import { OverwriteMergeModal } from "./OverwriteMergeModal";
import { baseConfig } from "../../utils/fineUploader";
import { validationSchema } from "../../data/collection";
import { setCurrentlyEditingCollection } from "../../slices/pagesSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Field, FieldArray, Formik } from "formik";

const userDefinedPropertyTypes = [
  { label: "Text", type: "text" },
  { label: "Integer", type: "int8" },
  { label: "Decimal", type: "numeric" },
];

export const CollectionsCreateEdit = () => {
  const { currentlyEditingCollection: collectionID } = useAppSelector((state) => state.pages.data);

  const initialCollectionValues = {
    name: "",
    type: CollectionType.Master,
    refreshEnabled: false,
  };

  const { data, isFetching, error } = useGetCollectionQuery(
    { ID: parseInt((collectionID ?? "").toString()) },
    { skip: !collectionID || collectionID === "create" }
  );

  // if not editing, show the form with initial values
  if (collectionID === "create") {
    return <CollectionsForm key="new" collection={initialCollectionValues} />;
  }

  if (isFetching) {
    return <div id="collection-create">Loading collection...</div>;
  }

  if (!data) {
    return <div id="collection-create">{error ? JSON.stringify(error) : "Collection not found"}</div>;
  }

  return <CollectionsForm key={collectionID} collection={data} />;
};

const PropertyEditor = ({
  property,
  i,
  handleChange,
  remove,
  errors,
  touched,
}: {
  property: Property;
  i: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: React.ChangeEvent<any>) => void;
  remove: (i: number) => void;
  errors?: { [key: string]: string };
  touched?: { [key: string]: boolean };
}) => {
  const [showEnum, setShowEnum] = useState(false);
  const toggleEnum = () => setShowEnum(!showEnum);

  const allowedValuesText = useMemo(
    () => property.allowedValues && property.allowedValues.join("\n"),
    [property.allowedValues]
  );

  const textToArray = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(target: any, prop: string) {
      return prop == "value" ? target[prop]?.split("\n").filter((v: string) => !!v) : target[prop];
    },
  };

  return (
    <div className="property" key={i} title={property.name}>
      <Field
        type="text"
        name={`properties.${i}.name`}
        placeholder="Property name"
        title="User facing name for the property"
        className={!!errors?.name && !!touched?.name ? "invalid" : ""}
      />
      <div className="select-holder">
        <Field as="select" name={`properties.${i}.type`} disabled={!!property.column}>
          {userDefinedPropertyTypes.map((type) => (
            <option key={type.type} value={type.type}>
              {type.label}
            </option>
          ))}
        </Field>
        {property.type === Type.Text && (
          <a onClick={toggleEnum} style={{ cursor: "pointer" }}>
            Restrict values {property.allowedValues?.length ? `(${property.allowedValues.length})` : ""} &gt;&gt;
          </a>
        )}
      </div>
      <div className="property-options">
        <div className="property-option">
          <Checkbox
            checked={property.required ?? false}
            label="Required"
            id={`properties.${i}.required`}
            name={`properties.${i}.required`}
            onChange={handleChange}
            value="true"
            title="If true, the property is marked as required for map actions"
          />
        </div>
        <div className="property-option">
          <Checkbox
            checked={property.hidden ?? false}
            label="Hidden"
            id={`properties.${i}.hidden`}
            name={`properties.${i}.hidden`}
            onChange={handleChange}
            value="true"
            title="If true, the property should be hidden from the user"
          />
        </div>
        <div className="property-option">
          <Checkbox
            checked={property.protected ?? false}
            label="Protected"
            id={`properties.${i}.protected`}
            name={`properties.${i}.protected`}
            onChange={handleChange}
            value="true"
            title="If true, users will not be able to edit this property"
          />
        </div>
      </div>
      <Field
        type="text"
        name="property.column"
        disabled
        placeholder="Property column"
        title="Name of the column for the property in the database"
      />
      <button
        className="btn del"
        onClick={() => remove(i)}
        title="Delete the property. Warning: all maps using this collection will be broken until a browser refresh."
      ></button>

      {showEnum && (
        <div className="enum-values" style={{ paddingLeft: "5%" }}>
          <p>Enter one value per line:</p>
          <textarea
            name={`properties.${i}.allowedValues`}
            defaultValue={allowedValuesText}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => handleChange({ ...e, target: new Proxy(e.target, textToArray) })}
            style={{ width: "70%", height: "6em" }}
          />
        </div>
      )}
    </div>
  );
};

const CollectionsForm = ({ collection }: { collection: Partial<ICollection> }) => {
  const { currentlyEditingCollection: collectionID } = useAppSelector((state) => state.pages.data);
  const dispatch = useAppDispatch();
  const indexesError = "";

  const [saveCollection, { isLoading: isSavingCollection }] = useSaveCollectionMutation();

  const [errorMsg, setErrorMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [prettyProgress, setPrettyProgress] = useState("");
  const [showMoreFiles, setShowMoreFiles] = useState(false);
  const [showAggregates, setShowAggregates] = useState(false);
  const [uploads, setUploads] = useState<Record<string, string>>({});

  const [shouldMerge, setShouldMerge] = useState<boolean>();

  const [isSaveAsMasterOpen, setIsSaveAsMasterOpen] = useState(false);
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);

  const areInProgressFiles = useMemo(
    () => Object.values(uploads).some((s) => ["uploading", "retrying upload", "queued"].includes(s)),
    [uploads]
  );

  const initialValues: Partial<ICollection> & { files?: FileList } = useMemo(
    () => ({
      // Initial values must be supplied for fields we want to require or Formik won't "touch"
      // the fields pre-submit, and thus we won't know to show validation error messages.
      name: undefined,
      srid: undefined,
      ...collection,
      properties: collection.properties?.filter((p) => !p.system),
    }),
    [collection]
  );
  const { entityID } = useAppSelector((state) => state.app);

  const { data: { fileTypesForHumans, acceptFiles } = {} } = useGetCollectionsMetaQuery();
  const { data: collections } = useGetCollectionsQuery();

  // show aggregates if the collection has an aggSql
  useEffect(() => {
    if (collection?.aggSql) {
      setShowAggregates(true);
    }
  }, [collection]);

  const close = () => dispatch(setCurrentlyEditingCollection(undefined));

  const cancelFiles = () => {
    setUploadComplete(false);
    setProgress(0);
    setPrettyProgress("");
    setShowMoreFiles(false);
    setErrorMsg("");
  };

  async function onSubmit(values: Partial<ICollection> & { files?: FileList }, setSubmitting: (s: boolean) => void) {
    setSubmitting(true);
    if (values.files?.length) {
      if (collection.ID && typeof shouldMerge === "undefined") {
        setIsMergeDialogOpen(true);
        return;
      }
      const fileIDs: string[] = [];
      const uploader: s3.FineUploaderBasic = new s3.FineUploaderBasic({
        ...baseConfig,
        callbacks: {
          onStatusChange: (id, oldStatus, newStatus) => {
            setUploads((prev) => ({ ...prev, [id]: newStatus }));
          },
          onError: (_id: unknown, _name: unknown, errorReason: string) => setErrorMsg(errorReason),
          onComplete: (
            _id: unknown,
            _name: unknown,
            response: { success?: boolean; error?: string; data: { ID: string } }
          ) => {
            if (response.success) {
              setErrorMsg("");
              fileIDs.push(response.data.ID);
            } else {
              setErrorMsg(response.error ?? "");
            }
          },
          onSubmitted: () => {
            const netUploads = uploader.getNetUploads();
            const uploads = uploader.getUploads();
            setUploadComplete(netUploads > 0 && netUploads === (Array.isArray(uploads) ? uploads.length : 1));
          },
          onTotalProgress: (totalUploadedBytes: number, totalBytes: number) => {
            const rawProgress = totalUploadedBytes / totalBytes;
            setProgress(rawProgress);
            setPrettyProgress(numeral(rawProgress).format("0.0%"));
          },
          onAllComplete: async () => {
            if (values.type === CollectionType.Master) {
              if (!collection.ID && uploader.getNetUploads() === 0) {
                return;
              }
              const uploads: FoundUploadItems[] = Array.isArray(uploader.getUploads())
                ? (uploader.getUploads() as FoundUploadItems[])
                : [uploader.getUploads() as FoundUploadItems];

              if (
                values.files &&
                values.files?.length > 0 &&
                uploader.getNetUploads() !== uploads.filter((u) => u.status === "upload successful").length
              ) {
                return;
              }
            }

            setErrorMsg("");
            try {
              await saveCollection({
                ...values,
                merge: shouldMerge,
                ...(fileIDs ? { files: fileIDs } : { files: undefined }),
              }).unwrap();
              close();
            } catch (e) {
              if (isFetchBaseQueryError(e)) {
                setErrorMsg(
                  ((e.data ?? {}) as { status?: { message?: string } }).status?.message ?? "An error occurred"
                );
              } else {
                setErrorMsg("An error occurred");
              }
            }
          },
        },
        validation: {
          acceptFiles: acceptFiles,
        },
        signature: {
          version: 4,
          endpoint: `${config.API_ROOT}/mapping/collections/upload/sign?entityID=${entityID}`,
        },
        uploadSuccess: {
          endpoint: `${config.API_ROOT}/mapping/collections/upload?entityID=${entityID}`,
        },
        objectProperties: {
          region: config.S3_REGION,
          key: function (id) {
            const directory = `entity_${entityID}/mapping/collections/`;

            return directory + uploader.getUuid(id) + `_` + uploader.getName(id);
          },
        },
      });

      uploader.addFiles(values.files);
      setSubmitting(false);
    } else {
      try {
        await saveCollection({ ...values, files: undefined }).unwrap();
        close();
      } catch (e) {
        if (isFetchBaseQueryError(e)) {
          setErrorMsg(((e.data ?? {}) as { status?: { message?: string } }).status?.message ?? "An error occurred");
        } else {
          setErrorMsg("An error occurred");
        }
      }
    }
  }

  return (
    <div id="collection-create">
      {collectionID === "create" ? (
        <h3>Create Collection</h3>
      ) : (
        <h3 title={`Collection ID: ${collection?.ID}`}>Edit &apos;{collection?.name}&apos;</h3>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(collections ?? [], collection)}
        onSubmit={async (values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue, handleBlur, touched }) => (
          <form className="form-horizontal" name="create" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="name">
                Name:
              </label>
              <Field
                className={classNameMapper({ invalid: !!errors.name && !!touched.name }, "col-md-6")}
                type="text"
                placeholder="Collection Name"
                name="name"
              />
              {errors.name && touched.name && <span className="text-danger">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="type">
                Type:
              </label>
              <div className="select-holder">
                <select
                  name="type"
                  value={values.type}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("refreshEnabled", false);
                    setFieldValue("indexes", []);
                  }}
                  disabled={Boolean(collection?.ID)}
                >
                  {CollectionTypes.map(({ type, name }) => (
                    <option key={type} value={type}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              {values.type === "transform" && (
                <span>
                  <Checkbox
                    name="refreshEnabled"
                    id="refreshEnabled"
                    checked={values.refreshEnabled}
                    onChange={handleChange}
                    label="Automatically Refresh"
                  />
                </span>
              )}
            </div>
            {values.type === "master" && (
              <section>
                <div className="form-group">
                  <fieldset>
                    <legend>Files</legend>
                    <div className="filenames">
                      <p className="text-danger error-message">{errorMsg}</p>
                      {prettyProgress && <p>{prettyProgress}</p>}
                      {(!values.files || values.files?.length === 0) && (
                        <>
                          <p>[no file(s) selected]</p>
                          <p>Supported file types:</p>
                          <ul>
                            {fileTypesForHumans?.map((fileType, i) => (
                              <li key={`file-type-${i}`}>{fileType}</li>
                            ))}
                          </ul>
                        </>
                      )}
                      {values.files && (
                        <>
                          {values.files.length > 0 &&
                            Array.from(values.files)
                              ?.slice(0, 2) // only show the first two here
                              .map((file, i) => <p key={`file-${i}`}>{file.name}</p>)}

                          {values.files?.length >= 3 && !showMoreFiles && (
                            <p className="show-more" onClick={() => setShowMoreFiles(true)}>
                              show {values.files?.length - 2} more...
                            </p>
                          )}
                          {values.files?.length >= 3 && showMoreFiles && (
                            <p className="show-more" onClick={() => setShowMoreFiles(false)}>
                              show less...
                            </p>
                          )}
                          {showMoreFiles &&
                            values.files.length > 0 &&
                            Array.from(values.files)
                              ?.slice(2)
                              .map((file, i) => <p key={`file-show-more-${i}`}>{file.name}</p>)}
                        </>
                      )}
                      {uploadComplete && <span className="text-success">File uploaded</span>}
                    </div>
                    {values.files?.length && (
                      <span
                        className="clear-files btn del"
                        onClick={() => {
                          cancelFiles();
                          setFieldValue("files", undefined);
                        }}
                        title={`Clear File${values.files.length > 1 ? "s" : ""}`}
                      />
                    )}
                  </fieldset>
                  <div className="file-controls">
                    {areInProgressFiles || (Object.keys(uploads).length && isSubmitting) || isSavingCollection ? (
                      <>
                        <p>{progress !== 1 ? "Uploading..." : "Processing..."}</p>
                        <div className={classNameMapper({ "progress-striped active": progress === 1 }, "progress")}>
                          <div
                            className={classNameMapper({ "progress-bar-success": progress === 1 }, "progress-bar")}
                            role="progressbar"
                            style={{ minWidth: "0px", width: `${progress * 100}%` }}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {values.files?.length && (
                      <span className="btn orange disabled">{collection.ID ? "Select New File" : "Select File"}</span>
                    )}
                    {(!values.files || values.files?.length === 0) && (
                      <span className="btn orange">
                        <span style={{ cursor: "pointer" }}>{collection.ID ? "Select New File" : "Select File"}</span>
                        <input
                          accept={acceptFiles?.join(", ")}
                          type="file"
                          multiple
                          title="Select a collection file to upload"
                          onChange={(e) => setFieldValue("files", e.target.files)}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    )}
                    {errors.files && <span className="text-danger">{errors.files}</span>}
                  </div>
                </div>
              </section>
            )}
            {values.type === CollectionType.Writable && (
              <section className="user-defined-properties">
                <div className="form-group">
                  <label className="col-md-3 control-label" htmlFor="srid">
                    SRID:
                  </label>
                  <Field
                    className={classNameMapper({ invalid: !!errors.srid && !!touched.srid }, "col-md-6")}
                    type="number"
                    placeholder="SRID (integer)"
                    name="srid"
                    list="srid-suggestions"
                  />
                  <datalist id="srid-suggestions">
                    <option
                      value="2163"
                      label="2163: US National Atlas Equal Area. Unit: Meter (IOGP deprecated)"
                    ></option>
                    <option value="2784" label="2784: NAD83(HARN) / Hawaii zone 3. Unit: Meter"></option>
                    <option value="2784" label="3857: Web Mercator. Unit: Meter"></option>
                    <option value="4269" label="4269: NAD83. Unit: Degree"></option>
                    <option value="4326" label="4326: WGS 84 / World Geodetic System 1984. Unit: Degree"></option>
                    <option value="9311" label="9311: NAD27 / US Equal Area. Unit: Meter (Replaces 2163)"></option>
                  </datalist>
                  {errors.srid && touched.srid && <span className="text-danger">{errors.srid}</span>}
                </div>
                <div className="form-group">
                  <h4>Extra Properties</h4>

                  {(!values.properties || values.properties?.length === 0) && <p>No User Defined Properties</p>}
                  <FieldArray name="properties">
                    {({ remove, push }) => (
                      <>
                        {values.properties?.map((property, i) => (
                          <PropertyEditor
                            property={property}
                            i={i}
                            key={i}
                            handleChange={handleChange}
                            remove={remove}
                            errors={Array.isArray(errors) ? errors[i] : errors.properties?.[i]}
                            touched={
                              Array.isArray(touched)
                                ? touched[i]
                                : // The type provided by formik isn't accurate, so we first assert this as unknown
                                  // before asserting the correct type.
                                  (touched.properties as unknown as { [key: string]: boolean }[])?.[i]
                            }
                          />
                        ))}
                        <div className="add-property" onClick={() => push({ type: "text" })}>
                          <button className="btn add"></button>
                          <span>Add Property</span>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </section>
            )}
            {values.type && [CollectionType.Transformation, CollectionType.Dynamic].includes(values.type) && (
              <>
                <SqlEditor
                  sql={values.sql ?? ""}
                  onChange={(sql) => setFieldValue("sql", sql)}
                  onBlur={() => handleBlur({ target: { name: "sql" } })}
                />
                {errors.sql && <p className="text-danger error-message">{errors.sql}</p>}
              </>
            )}
            {values.type !== CollectionType.Master && <p className="text-danger error-message">{errorMsg}</p>}
            {values.type === CollectionType.Dynamic && (
              <div className="date-column form-group">
                <div className="col-md-3 control-label">
                  <label htmlFor="dateColumn">Date Column:</label>
                </div>
                <div className="col-md-9">
                  <Field id="dateColumn" type="text" name="dateColumn" />
                </div>
              </div>
            )}
            <div className="text-danger">{indexesError}</div>
            {collection.ID && values.type != "dynamic" && (
              <IndexesCreator
                collection={collection as ICollection}
                indexes={values.indexes ?? []}
                onChange={(indexes) => {
                  setFieldValue("indexes", indexes);
                }}
              />
            )}
            {collection.ID && values.type !== CollectionType.Writable && (
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="idColName">
                  ID Column:
                </label>
                <div className="select-holder">
                  <select name="idColName" id="idColName" value={values.idColName ?? ""} onChange={handleChange}>
                    <option value="">None</option>
                    {collection.properties?.map((prop) => (
                      <option key={prop.column} value={prop.column}>
                        {prop.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="col-md-3 control-label">
                <label htmlFor="noun">Noun (singular—for action messages):</label>
              </div>
              <div className="col-md-9">
                <Field id="noun" type="text" name="noun" />
              </div>
            </div>
            <div>
              <Checkbox
                checked={showAggregates}
                onChange={() => setShowAggregates((v) => !v)}
                label="Add Aggregate Values"
              />
              {showAggregates && (
                <div className="aggregates">
                  <SqlEditor
                    sql={values.aggSql ?? ""}
                    onChange={(sql) => setFieldValue("aggSql", sql)}
                    onBlur={() => handleBlur({ target: { name: "aggSql" } })}
                  />
                </div>
              )}
            </div>
            <div className="controls">
              <span className="btn plain" onClick={close}>
                Close
              </span>
              {collection.ID && values.type === CollectionType.Transformation && (
                <span className="btn" onClick={() => setIsSaveAsMasterOpen(true)}>
                  Save as Master
                </span>
              )}
              <Button
                type="submit"
                className="btn orange"
                disabled={
                  isSubmitting ||
                  areInProgressFiles ||
                  (Object.keys(uploads).length > 0 && isSubmitting) ||
                  isSavingCollection
                }
              >
                <span>{(collection?.ID ? "Updat" : "Creat") + (isSubmitting ? "ing" : "e")}</span>
                {isSubmitting && <SmallLoadingIndicator />}
              </Button>
            </div>
            {collection.ID && isSaveAsMasterOpen && (
              <SaveAsMasterModal collectionID={collection.ID} onClose={() => setIsSaveAsMasterOpen(false)} />
            )}
            {collection.ID && isMergeDialogOpen && (
              <OverwriteMergeModal
                onCancel={() => setIsMergeDialogOpen(false)}
                onClose={(merge) => {
                  setShouldMerge(merge);
                  setIsMergeDialogOpen(false);
                  handleSubmit();
                }}
              />
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
