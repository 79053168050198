import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetUserQuery, useUpdateUserMutation, useCreateUserMutation } from "../../slices/apiSlice";
import { useDynamicFormik } from "../../hooks/useDynamicFormik";
import { Checkbox } from "../../design/Checkbox";

const brands = { "Epiphany Analytics": "default", VisionMap: "morrow-hill" };

export const EditUser = () => {
  const navigate = useNavigate();
  const { userID } = useParams<{ userID: string }>();
  const isEditMode = Boolean(userID);

  const [update, { isLoading: isSavingUser }] = useUpdateUserMutation();
  const [create, { isLoading: isCreatingUser }] = useCreateUserMutation();

  const { data: user } = useGetUserQuery(parseInt(userID ?? ""), {
    skip: !isEditMode,
  });

  const { values, errors, handleChange, handleSubmit, setValues } = useDynamicFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      uname: "",
      password: "",
      passwordConfirm: "",
      allowedLoginBrands: [],
      active: true,
      multisession: true,
      ...user,
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required("First name is required"),
      lname: Yup.string().required("Last name is required"),
      email: Yup.string().email().required("Email is required"),
      uname: Yup.string().min(5).required("Username is required"),
      password: Yup.string().min(6),
      passwordConfirm: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      if (isEditMode) {
        await update({ ID: parseInt(userID ?? ""), ...values });
      } else {
        await create({ ...values });
      }
      navigate("/admin/users-and-entities");
    },
  });

  useEffect(() => {
    if (user && isEditMode) {
      setValues({
        ...user,
        password: "",
        passwordConfirm: "",
      });
    }
  }, [user, isEditMode, setValues]);

  return (
    <>
      <div className="user-details">
        <div className="manage-header">User Details</div>
        <div className="manage-body">
          {errors && (
            <div className="text-danger">
              {Object.entries(errors).map((error) => (
                <span key={`error-${error}`}>{error}</span>
              ))}
            </div>
          )}
          <div className="col-md-12">
            <form name="userForm" onSubmit={handleSubmit}>
              <label htmlFor="first">First Name:</label>
              <input
                id="first"
                name="fname"
                type="text"
                placeholder="FIRST NAME"
                value={values.fname ?? ""}
                onChange={handleChange}
              />
              {errors.fname && <span className="text-danger">{errors.fname}</span>}
              <label htmlFor="last">Last Name:</label>
              <input
                id="last"
                name="lname"
                type="text"
                placeholder="LAST NAME"
                value={values.lname ?? ""}
                onChange={handleChange}
              />
              {errors.lname && <span className="text-danger">{errors.lname}</span>}
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="EMAIL"
                value={values.email ?? ""}
                onChange={handleChange}
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
              <label htmlFor="username">Username:</label>
              <input
                id="username"
                name="uname"
                type="text"
                placeholder="USERNAME"
                value={values.uname ?? ""}
                onChange={handleChange}
              />
              {errors.uname && <span className="text-danger">{errors.uname}</span>}
              <div className="password">
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="PASSWORD"
                  value={values.password ?? ""}
                  onChange={handleChange}
                />
                <input
                  type="password"
                  name="passwordConfirm"
                  placeholder="CONFIRM"
                  value={values.passwordConfirm ?? ""}
                  onChange={handleChange}
                />
                {errors.password && <span className="text-danger">{errors.password}</span>}
                {errors.passwordConfirm && <span className="text-danger">{errors.passwordConfirm}</span>}
              </div>
              <label className="login-brands-label">Login restricted to:</label>
              {Object.entries(brands).map(([name, brand]) => (
                <span className="login-brands" key={brand}>
                  <input
                    id={`allowed-login-brand-${brand}`}
                    type="checkbox"
                    className={values.allowedLoginBrands?.includes(brand) ? "_checked" : ""}
                    onChange={() => handleChange({ target: { name: "allowed", value: brand } })}
                  />
                  <label className="checkbox col-md-12" htmlFor={`allowed-login-brand-${brand}`}>
                    {name}
                  </label>
                </span>
              ))}
              <div className="user-settings">
                <Checkbox id="active" name="active" checked={values.active} onChange={handleChange} label="Activated" />
                <Checkbox
                  id="multisession"
                  name="multisession"
                  checked={values.multisession}
                  onChange={handleChange}
                  label="Multisession user"
                />
              </div>
              <div className="controls">
                <span className="btn" onClick={() => navigate("/admin/users-and-entities")}>
                  Close
                </span>
                {isSavingUser || isCreatingUser ? (
                  <span className="btn disabled">
                    <span className="small-loading-indicator"></span>
                    <span>Saving...</span>
                  </span>
                ) : (
                  <input className="btn" type="submit" value="Save" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
