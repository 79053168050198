import { ReactNode, useRef, useState } from "react";

import { DOMAIN, protocol } from "../configuration";
import { CompanySelector } from "./CompanySelector";
import { logout } from "../services/user";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useAppSelector } from "../hooks/useAppSelector";
import { Link } from "react-router-dom";
import { useMenu } from "../hooks/useMenu";
import { ReportProblemModal } from "./ReportProblemModal";

declare global {
  interface Window {
    epiVersion: { hash: string; branch: string; buildDate: string; publicVersion: string; title: string };
  }
}

export const Header = () => {
  const { h1, session } = useAppSelector((state) => state.app);

  const Menu = useMenu();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);

  const closeMenu = () => setIsMenuOpen(false);
  useOutsideClick(menuRef, closeMenu);

  const { hash, branch, buildDate, publicVersion, title } = window.epiVersion;

  /**
   * Get the base route for this user. Normally home, but if the user
   * has access only to a single entity and module, then use that module,
   * e.g., mapping.
   * @return {string}
   */
  function getBaseRoute() {
    if (!session || session.entityList.length != 1) return "";

    const enabled = Menu.enabled;
    const entityID = session.entityList[0].ID;

    if (enabled.length != 1) return "/home";

    return `/${enabled[0].id}/${entityID}`;
  }

  const handleLogout = async () => {
    await logout();
    window.location.href = protocol + "//" + DOMAIN;
  };

  return (
    <BaseHeader h1={h1} baseRoute={getBaseRoute()}>
      <ul className="nav navbar-nav navbar-right">
        <li>
          <CompanySelector />
        </li>
        {session && (
          <li id="user-menu">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsMenuOpen((val) => !val);
              }}
            >
              {session?.fname}
            </a>
            {isMenuOpen && (
              <ul className="dropdown-menu open" ref={menuRef}>
                <li>
                  <Link to={"my-profile"} onClick={closeMenu}>
                    My Profile
                  </Link>
                </li>
                <li className="divider"></li>
                {session?.permissions.superuser && (
                  <li>
                    <Link to={"admin"} onClick={closeMenu}>
                      Admin
                    </Link>
                  </li>
                )}
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    Logout
                  </a>
                </li>
                <li className="divider"></li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      setModalOpen(true);
                      closeMenu();
                    }}
                  >
                    Report a Problem
                  </a>
                </li>
                <li className="version" title={`${title} ${hash} - ${branch} - Built on ${buildDate}")`}>
                  {title} {publicVersion}
                </li>
              </ul>
            )}
          </li>
        )}
      </ul>
      <ReportProblemModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </BaseHeader>
  );
};

export const BaseHeader = ({
  h1 = "",
  baseRoute = "",
  children,
}: {
  h1: string;
  baseRoute: string;
  children?: ReactNode;
}) => (
  <header>
    <nav id="top-nav" className="navbar navbar-default">
      <Link to={baseRoute} id="navbar-logo" className="navbar-brand" />
      <div className="collapse navbar-collapse">
        <ul className="nav navbar-nav">
          <li id="page-header">
            <h1>{h1}</h1>
          </li>
        </ul>
        {children}
      </div>
    </nav>
  </header>
);
